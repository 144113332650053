<template>
	<div class="block" v-if="news && news.length">
		<div class="wrapper">
			<div class="header-block">
				<h2 class="header-block_title">Новости</h2>
				
				<router-link :to="newsLink" class="header-block_link">Все новости</router-link>
			</div>
			
			
			<div class="last-news">
				<div class="last-news_row" v-for="newsItem in news" :key="newsItem.id">
					<router-link :to="{ name: 'news-item', params: {id: newsItem.id} }" class="last-news_title">
						{{ newsItem.title }}
					</router-link>
					
					<div class="last-news_date">
						{{ getTime(newsItem.pub_date) }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: ['sport'],

		data() {
			return {
				news: null
			}
		},

		created() {
			let params = {
				limit: 3, page: 1
			};

			if (this.sport) {
				params.sport = this.sport;
			}

			this.axios.get('/api/news', {params: params})
			.then(({data}) => {
				this.news = data.data;
			});
		},
		computed: {
			newsLink() {
				return this.sport ? '/news?page=1&sport=' + this.sport : '/news';
			}
		},
		methods: {
			getTime(date) {
				return moment(date).locale('ru').format('D MMMM Y, HH:mm');
			},
		}
	}
</script>

<style scoped>

</style>