import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import moment from 'moment-timezone';
import i18n from "./plugins/i18n";

const VueInputMask = require('vue-inputmask').default;

moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);

Vue.config.productionTip = location.host === 'sport.tele2.ru';
Vue.config.local = true;

Vue.prototype.axios = window.axios = axios;

if (location.host.search('localhost') !== -1 || location.host.search('nlt-sm.ru') !== -1) {
	axios.defaults.baseURL = 'http://tele2.roscontent.ru';
	Vue.prototype.$hostname = 'http://tele2.roscontent.ru';
} else {
	axios.defaults.baseURL = '';
	Vue.prototype.$hostname = '';
	Vue.config.local = false;
}

window.isVisible = function ($block, offset) {
	if (!$block.length) return;

	var blockTop = $block.offset().top,
		blockHeight = $block.innerHeight(),
		scrollTop = $(window).scrollTop(),
		windowHeight = $(window).height();

	offset = offset || 0;

	return !!(scrollTop >= (blockTop - windowHeight + offset)
		&& scrollTop < blockTop + blockHeight);
};

window.events = new Vue();

window.auth = {
	auth: false,
	subscribed: false
};

window.getTimeZoneNum = function () {
	var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
	return parseInt((offset < 0 ? "+" : "-") + ("" + Math.floor(o / 60)).slice(-2));
};

Vue.use(VueInputMask);
new Vue({
	i18n,
	router,
	render: h => h(App)
}).$mount('#app');
