<template>
	<div id="app" v-cloak>
		<header-block></header-block>
		
		<div>
			<router-view :key="$route.fullPath"></router-view>
		</div>
		
		<footer-block></footer-block>
	</div>
</template>

<script>
	import HeaderBlock from "./components/Layout/Header";
	import FooterBlock from "./components/Layout/Footer";
	
	export default {
		components: {
			HeaderBlock,
			FooterBlock
		},
		
	}
</script>

<style lang="less">
	@import "./assets/less/main";
</style>