<template>
	<div>
		<div class="video">
			<div class="wrapper">
				<h1 class="h1-like">{{ video.title }}</h1>
				
				<div class="video_file">
					<video id="video" controls="controls" style="max-width: 600px; width: 100%; display:block; margin: 0 auto;" :src="$hostname + video.video_file">
					</video>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				video: {}
			}
		},
		
		created() {
			this.axios.get('/api/videos/' + this.$route.params.id)
				.then(({data}) => {
					this.video = data;
				});
		},
		mounted() {
			$('#video').one('play', () => {
				window.events.$emit('videoPlaying');
			})
		}
	}
</script>

<style scoped>

</style>