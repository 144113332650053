<template>
	<div class="games">
		<div class="wrapper">
			<h1 class="games_title">Игры</h1>
			
			<div class="games_row">
				<div class="games_col" v-for="(game, id) in games" :key="id">
					<div class="game-block_item">
						<a class="game-block_image">
							<img :src="$hostname + game.image_ref" alt="">
						</a>
						
						<a :href="game.path" class="game-block_title">
							{{ game.title }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				games: []
			}
		},
		
		created() {
			this.axios.get('/api/games').then(({data}) => {
				this.games = data;
			});
		}
	}
</script>

<style scoped>

</style>