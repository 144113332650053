<template>
	<footer class="footer">
		<div class="wrapper">
			<div class="footer_row">
				<div class="footer_text">© {{ year() }} © Tcell 2020</div>
				<a class="footer_link" href="/oferta.pdf">Условия сервиса</a>
				<a @click.prevent="unsubscribe" v-if="auth.subscribed" href="" class="footer_btn">Отписаться</a>
			</div>
		</div>
	</footer>
</template>

<script>
	import moment from 'moment';
	
	export default {
		data() {
			return {
				auth: window.auth,
				showForm: false
			}
		},
		methods: {
			year() {
				return moment().format('Y')
			},
			unsubscribe() {
				this.axios({url: '/a1/unsubscribe', baseURL: '/'}).then(() => {
					window.auth.subscribed = false;
					this.showForm = true;
				})
			}
		}
	}
</script>